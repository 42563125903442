<template>
    <perfil-form acao="ATUALIZAR" :perfil="perfil" :cancelar="cancelar" @atualizar="obterPerfil()" @salvar="atualizar($event)" :erros="erros"></perfil-form>
</template>

<script>
import PerfisServices from './services';
import PerfilForm from './PerfilForm';

export default {
    components: {
        PerfilForm,
    },

    data() {
        return {
            perfil: null,
            erros: [],
            atualizado: false,
        };
    },

    methods: {
        obterPerfil() {
            this.$store.dispatch('addRequest');
            PerfisServices.obterPorId(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.perfil = response.data;
                } else {
                    this.perfil = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Perfis_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        atualizar(perfilAtualizado) {
            this.$store.dispatch('addRequest');
            let perfilAtualizadoDto = {
                perfilAcessoId: this.perfil.perfilAcessoId,
                codigo: perfilAtualizado.codigo,
                descricao: perfilAtualizado.descricao,
            };
            if (this.$temAcessoView('CTRLACS-PA-05')) {
                perfilAtualizadoDto.nivel = perfilAtualizado.nivel;
            }
            PerfisServices.atualizar(perfilAtualizadoDto).then(response => {
                if (response && response.success) {
                    this.perfil = response.data;
                    this.atualizado = true;
                    this.$toast.add({ severity: 'success', summary: 'Atualização de Perfil', detail: 'Perfil de acesso atualizado com sucesso', life: 3000 });
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterPerfil();
    },
};
</script>
